import axios from 'axios';

export default class CategoryGateway
{

    static getCategories(successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/category/').then(successCallback).catch(errorCallback);
    }

    static createCategory(category_name,successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/category/',
            {
                name: category_name,
            }).then(successCallback).catch(errorCallback);
    }

    static updateCategory(category_id,category_name,successCallback,errorCallback)
    {
        axios.patch(process.env.REACT_APP_API_URL+'/category/'+category_id+'/',
            {
                name: category_name,
            }).then(successCallback).catch(errorCallback);
    }

    static getCategory(category_id,successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/category/'+category_id+'/').then(successCallback).catch(errorCallback);
    }

    static deleteCategory(category_id,successCallback,errorCallback)
    {
        axios.delete(process.env.REACT_APP_API_URL+'/category/'+category_id+'/').then(successCallback).catch(errorCallback);
    }

}

