import React, {Component} from "react";
import CataloguesGateway from "../../Gateways/CatalogueGateway";
import {Callout, Cell, Colors} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationSquare, faSpinnerThird} from "@fortawesome/pro-duotone-svg-icons";
import Catalogue from "./Catalogue";

export default class CatalogueList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            catalogues: undefined,
        };

    }

    loadedCatalogues = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,catalogues: response.data});
            //ggf iwie zu state mit dem items mappen, damit acuh das neu objekt drin is
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Catalogues'});
    };

    componentDidMount() {
        CataloguesGateway.getCatalogues(this.loadedCatalogues,this.errorLoading);
    }


    render() {

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const catalogue_new_item =
            <Cell auto>
                <Catalogue></Catalogue>
            </Cell>

        const catalogue_list = this.state.catalogues &&
            <Cell auto>
                {this.state.catalogues.map(
                    catalogue => (
                        <Catalogue id={catalogue.id} name={catalogue.name} description={catalogue.description}></Catalogue>
                ))}
            </Cell>;

        // const empty_catalogue = !this.state.catalogue &&
        const empty_catalogue = 
            <Cell auto>
                <h5> <FontAwesomeIcon icon={faExclamationSquare}></FontAwesomeIcon> Keine Kataloge vorhanden</h5>
            </Cell>

        if (this.state.loading) {
            return(
                <FontAwesomeIcon icon={faSpinnerThird} spin />
            );
        }
        else {
            return (
                <>
                    {DivErrorMessage}
                    {catalogue_new_item}
                    {catalogue_list || empty_catalogue }
                </>
            );
        }



    }
}

