import React, {Component} from "react";
import {Callout, Cell, Colors} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationSquare, faSpinnerThird} from "@fortawesome/pro-duotone-svg-icons";
import Category from "./Category";
import CategoriesGateway from "../../Gateways/CategoryGateway";

export default class CategoryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            categories: undefined,
        };

    }

    loadedCategories = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,categories: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Categories'});
    };

    componentDidMount() {
        CategoriesGateway.getCategories(this.loadedCategories,this.errorLoading);
    }


    render() {

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const catalogue_new_item =
            <Cell auto>
                <Category></Category>
            </Cell>

        const categories_list = this.state.categories &&
            <Cell auto>
                {this.state.categories.map(
                    category_item => (
                        <Category key={category_item} id={category_item}></Category>
                    ))}
            </Cell>;

        const empty_catalogue =
            <Cell auto>
                <h5> <FontAwesomeIcon icon={faExclamationSquare}></FontAwesomeIcon> Keine Kategorien vorhanden</h5>
            </Cell>

        if (this.state.loading) {
            return(
                <FontAwesomeIcon icon={faSpinnerThird} spin />
            );
        }
        else {
            return (
                <>
                    {DivErrorMessage}
                    {catalogue_new_item}
                    {categories_list || empty_catalogue }
                </>
            );
        }



    }
}

