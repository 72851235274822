import React, {Component} from 'react';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { Cell, Grid} from "react-foundation";
import {GridContainer} from "react-foundation";
import CategoryList from "./Categories/CategoryList";
import MainMenu  from "./MainMenu";

export default class CategoryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
        };

    }

    componentDidMount() {
        this.setState({loading: false});
    }


    render() {
        const catalogue_list =
            <Cell auto>
                <CategoryList></CategoryList>
            </Cell>;

        if (this.state.loading) {
            return(
                 <FontAwesomeIcon icon={faSpinnerThird} spin />
            );
        }
        else {
            return(
                    <GridContainer full large-offset-2>
                        <MainMenu current={this}/>
                        <Grid full>

                            <Cell large={'auto'} small={'auto'}>
                                {catalogue_list}
                            </Cell>
                        </Grid>
                    </GridContainer>
            );
        }
    }
}

