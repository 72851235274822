import React, {Component} from 'react';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { Cell, Grid, Menu, MenuItem, MenuText, TopBar, TopBarLeft} from "react-foundation";
import {GridContainer} from "react-foundation";
import CatalogueItemList from "./CatalogueItems/CatalogueItemList";



export default class CatalogueItemPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            catalogue_id: props.match.params.id,
        };
    }

    componentDidMount() {
        this.setState({loading: false});
    }


    render() {
        const catalogueitem_list =
            <Cell auto>
                <CatalogueItemList catalogue_id={this.state.catalogue_id}></CatalogueItemList>
            </Cell>;


        if (this.state.loading) {
            return(
                 <FontAwesomeIcon icon={faSpinnerThird} spin />
            );
        }
        else {
            return(
                    <GridContainer full large-offset-2>
                        <Cell full>
                            <TopBar>
                                <TopBarLeft>
                                    <Menu>
                                    <MenuText>Logistics NG</MenuText>
                                        <MenuItem><a href={'#/'}>Home </a></MenuItem>
                                        <MenuItem><a href={'#/catalogues/'}>Kataloge </a></MenuItem>
                                        <MenuItem><a href={'#/categories/'}>Kategorien </a></MenuItem>
                                        <MenuItem><a href={'#/Logoff'}>Abmelden </a></MenuItem>
                                    </Menu>
                                </TopBarLeft>
                            </TopBar>

                        </Cell>
                        <Grid full>
                            <Cell >
                                {catalogueitem_list}
                            </Cell>
                        </Grid>

                    </GridContainer>
            );
        }
    }
}

