import React, {Component} from 'react';
import UserGateway from "../Gateways/UserGateway";
import LdapProfilePage from "./Profile/LdapProfilePage";
import LocalProfilePage from "./Profile/LocalProfilePage";
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import {Callout, Cell, Colors, Grid } from "react-foundation";
import AuthStorage from "../Storages/AuthStorage";
import {GridContainer} from "react-foundation";
import MainMenu from "./MainMenu";


export default class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            me: undefined
        };

    }

    loadedMe = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,me: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Profile Data'});
        if(error.status === 401)
        {
            AuthStorage.removeSessionKey();
        }
    };

    componentDidMount() {
        UserGateway.getMe(this.loadedMe,this.errorLoading);
    }


    render() {
        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        // const me_general = this.state.me && this.state.me.member_since &&
        //     <div >Member Since : {this.state.me.member_since}</div>

        const me_local = this.state.me && this.state.me.type && this.state.me.type==='local' &&
            <Cell auto>
                <LocalProfilePage></LocalProfilePage>
            </Cell>;


        const me_ldap = this.state.me && this.state.me.type && this.state.me.type==='ldap' &&
            <Cell auto >
                <LdapProfilePage ></LdapProfilePage>
            </Cell>;





        if (this.state.loading) {
            return(
                 <FontAwesomeIcon icon={faSpinnerThird} spin />
            );
        }
        else {
            return(
                    <GridContainer full large-offset-2>
                        <MainMenu current={this}/>
                        <Cell full>
                            {DivErrorMessage}
                        </Cell>
                        {/*{me_general}*/}
                        <Grid full>
                            <Cell large={'auto'} small={'auto'}>
                                {me_local || me_ldap}
                            </Cell>
                        </Grid>


                        <Cell x></Cell>
                    </GridContainer>
            );
        }
    }
}

