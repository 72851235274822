import axios from 'axios';

export default class LdapProfileGateway
{
    static loadProfile(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/me/ldap/').then(successCallback).catch(errorCallback);
    }

    static loadEmail(successCallback,errorCallback)//todo implement this api endpoint , maybe for also other details
    {
        axios.get(process.env.REACT_APP_API_URL+'/me/ldap/email').then(successCallback).catch(errorCallback);
    }

}
