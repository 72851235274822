import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import AuthStorage from "./Storages/AuthStorage";
import axios from "axios/index";
import LogOffPage from "./Pages/LogOffPage";
import CataloguesPage from './Pages/CataloguesPage';
import CatalogueItemPage from "./Pages/CatalogueItemPage";
import CategoryPage from "./Pages/CategoryPage";
class App extends Component
{
    constructor(props)
    {
        super(props);
        axios.defaults.timeout = 10000;
        axios.defaults.headers.common['Authorization'] = AuthStorage.getSessionKey();
    }


    render()
    {
        if(AuthStorage.isLoggedIn())
        {
            return (
                <Switch>
                    <Route path="/logoff" component={LogOffPage} />

                    <Route path="/catalogues/:id" component={CatalogueItemPage}/>
                    <Route path="/catalogues" component={CataloguesPage} />
                    <Route path="/categories" component={CategoryPage} />
                    <Route path="/" component={HomePage}/>
                </Switch>
            );
        }
        else
        {
            return (
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/" component={LoginPage}/>
                </Switch>
            );
        }
  }

}


export default App;
