import React, {Component} from "react";
import {Callout, Colors} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSave,
    faLock,
    faUnlock,
    faSync,
    faEraser,
    faGlobeEurope, faDoorOpen, faDoorClosed
} from "@fortawesome/pro-duotone-svg-icons";
import CatalogueGateway from "../../Gateways/CatalogueGateway";
import {Link} from "react-router-dom";

export default class Catalogue extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,
            name: props.name || "",
            description: props.description || "",
            editable: !props.id && !props.name && !props.description && true,
            errorMessage: undefined
        };
    }

    componentDidMount() {

    }

    handleChangeName = event => {
         this.state.editable && this.setState({name: event.target.value});
    };

    handleChangeDescription = event => {
        this.state.editable && this.setState({description: event.target.value});
    };

    handleChangeWriteProtection = event => {
        this.setState({editable: !this.state.editable});
    };

    handleChangeSave = event =>{
        if(!this.state.editable)
        {
            return;
        }
        if(this.state.name.trim() === "")
        {
            this.setState({errorMessage: "Name should not be empty"});
            return;
        }
        if(!this.state.id)
        {
            this.setState({loading: true});
            CatalogueGateway.createCatalogue(this.state.name,this.state.description,this.handleDataSyncOK,this.handleDataSyncFailure);
        }
        else
        {
            this.setState({loading:true});
            CatalogueGateway.updateCatalogue(this.state.id,this.state.name,this.state.description,this.handleDataSyncOK,this.handleDataSyncFailure);
        }
    };



    handleChangeSync = event => {
        this.setState({loading:true});
        CatalogueGateway.getCatalogue(this.state.id,this.handleDataSyncOK,this.handleDataSyncFailure)
    };

    handleDelete = event => {
      this.setState({loading: true});
      CatalogueGateway.deleteCatalogue(this.state.id,this.handleDataSyncOK,this.handleDataSyncFailure)
    };

    handleDataSyncOK = response => {
        if(response.data && (response.status === 200 || response.status === 201))
        {
            this.setState({
                id: response.data.id,
                name: response.data.name,
                description: response.data.description,
                loading:false});
        }
        else if(response.status === 204)
        {
            this.setState({
                id: undefined,
                name: "",
                description: "",
                loading: false});
        }
    };
    handleDataSyncFailure = error => {
        this.setState({loading:false,
            errorMessage: error.data});
    };

    render() {

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const catalogueItem =
                        <div className={"card"} id={this.state.id}>
                            <div className={"card-divider"}>
                                <div className="grid-x grid-margin-x">
                                    <div className="cell small-7">
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeWriteProtection}>
                                            { !this.state.editable &&<FontAwesomeIcon icon={faLock}/> }
                                            { this.state.editable &&<FontAwesomeIcon icon={faUnlock}/> }
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeSave} disabled={!this.state.editable}>
                                            <FontAwesomeIcon icon={faSave}/>
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeSync} disabled={!this.state.editable || (!this.state.id) || this.state.loading} >
                                            <FontAwesomeIcon icon={faSync} spin={this.state.loading}/>
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleDelete} disabled={!this.state.editable || (!this.state.id)}>
                                            <FontAwesomeIcon icon={faEraser}/>
                                        </button>
                                        <button type={"button"} className={"hollow button"} disabled={!this.state.editable || (!this.state.id)}>
                                            <FontAwesomeIcon icon={faGlobeEurope}/>
                                        </button>
                                        <Link to={""+this.state.id}>
                                            <button type={"button"} className={"hollow button"} disabled={!this.state.id}>
                                                { !this.state.id &&<FontAwesomeIcon icon={faDoorClosed}/> }
                                                { this.state.id &&<FontAwesomeIcon icon={faDoorOpen}/> }
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="cell auto">
                                        <input disabled={!this.state.editable} type={"text"}  placeholder ="Katalogname" value={this.state.name} onChange={this.handleChangeName}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"card-section"}>
                                <textarea disabled={!this.state.editable} placeholder={"Beschreibung"} onChange={this.handleChangeDescription} value={this.state.description}>
                                </textarea>

                            </div>
                        </div>;

        return (
            <>
                {DivErrorMessage}
                {catalogueItem}
            </>
        );
    }
}