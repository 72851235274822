import React, {Component} from "react";
import {Cell, Menu, MenuItem, MenuText, TopBar, TopBarLeft} from "react-foundation";

export default class MainMenu extends Component{

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: props.current
        }
    }

    componentDidMount() {
        this.setState({loading: false});
    }


    render() {
        return(
            <Cell full>
                <TopBar>
                    <TopBarLeft>
                        <Menu>
                            <MenuText>Logistics NG</MenuText>
                            <MenuItem><a href={'#/'}>Home </a></MenuItem>
                            <MenuItem><a href={'#/catalogues/'}>Kataloge </a></MenuItem>
                            <MenuItem><a href={'#/categories/'}>Kategorien </a></MenuItem>
                            <MenuItem><a href={'#/Logoff'}>Abmelden </a></MenuItem>
                        </Menu>
                    </TopBarLeft>
                </TopBar>

            </Cell>
            )
    }
}