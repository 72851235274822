import React, {Component} from "react";
import {Callout, Cell, Colors} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationSquare, faSpinnerThird} from "@fortawesome/pro-duotone-svg-icons";
import CatalogueItem from "./CatalogueItem";
import CatalogueItemGateway from "../../Gateways/CatalogueItemGateway";

export default class CatalogueItemList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            catalogue_id: props.catalogue_id,
            catalogue_items: undefined,
        };

    }

    loadedCatalogues = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,catalogue_items: response.data});
            //ggf iwie zu state mit dem items mappen, damit acuh das neu objekt drin is
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Catalogues'});
    };

    componentDidMount() {

        CatalogueItemGateway.getCatalogueItems(this.state.catalogue_id,this.loadedCatalogues,this.errorLoading);
    }


    render() {

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const catalogue_new_item =
            <Cell auto>
                <CatalogueItem catalogue_id={this.state.catalogue_id}></CatalogueItem>
            </Cell>

        const catalogue_item_list = this.state.catalogue_items &&
            <Cell auto>
                {this.state.catalogue_items.map(
                    catalogue_item => (
                        <CatalogueItem key={catalogue_item} catalogue_id={this.state.catalogue_id} id={catalogue_item}></CatalogueItem>
                ))}
            </Cell>;

        const empty_catalogue =
            <Cell auto>
                <h5> <FontAwesomeIcon icon={faExclamationSquare}/> Keine Items vorhanden</h5>
            </Cell>;

        if (this.state.loading) {
            return(
                <FontAwesomeIcon icon={faSpinnerThird} spin />
            );
        }
        else {
            return (
                <>
                    <h1> Katalog {this.state.catalogue_id}</h1>
                    {DivErrorMessage}
                    {catalogue_new_item}
                    {catalogue_item_list || empty_catalogue }
                </>
            );
        }



    }
}

