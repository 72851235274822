import React, {Component} from 'react';
import UserGateway from "../Gateways/UserGateway";
import AuthStorage from "../Storages/AuthStorage";
import ReCAPTCHA from "react-google-recaptcha";
import {
    Button,
    Callout,
    Cell,
    Colors,
    GridContainer,
    Menu,
    MenuText,
    TopBar,
    TopBarLeft
} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/pro-duotone-svg-icons";


class LoginPage extends Component {

    ReCaptchaRef = React.createRef();

    constructor(props)
    {
        super(props);
        this.state = {
            username : '',
            password: '',
            recaptcha: undefined,
            loading: false,
            errorMessage: undefined,
        };
    }


    handleChangeUsername = (event) => {
        this.setState({username: event.target.value});
    };

    handleChangePassword = (event) => {
        this.setState({password: event.target.value});
    };

    handleChangeRecaptcha = (event) => {
        const ReCaptchaValue = this.ReCaptchaRef.current.getValue();
        this.setState({recaptcha: ReCaptchaValue});
    };

    login = (event)=> {
        event.preventDefault();
        this.setState({loading: true});
        if(this.state.username.trim() === "")
        {
            this.setState({loading: false, errorMessage: "Enter a Username!"});
        }
        else if(this.state.password.trim() === "")
        {
            this.setState({loading: false, errorMessage: "Enter a Password!"});
        }
        else if(process.env.REACT_APP_RECAPTCHA_ENABLED && this.ReCaptchaRef.current.getValue() === "")
        {
            this.setState({loading: false, errorMessage: "Please fill out the Recaptcha"});
        }
        else
        {
            UserGateway.login(this.state.username,this.state.password,this.state.recaptcha,this.handleLoginResponse,this.handleLoginError);
            if(process.env.REACT_APP_RECAPTCHA_ENABLED)
            {
                this.ReCaptchaRef.current.reset();
            }

        }
    };

    handleLoginResponse = (response) => {
        if(response.data && response.data.sessionid)
        {
            AuthStorage.saveSessionKey(response.data.sessionid);
            this.setState({loading: false, successMessage: "Login Succeeded"});
            this.props.history.push("/");//redirect to main after authentication
            window.location.reload();
        }
        else
        {
            this.setState({loading:false});
        }
    };

    handleLoginError = (error) => {
        if(error.response && error.response.status === 422 && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error });
        }
        else if(error.response && error.response.status === 400)
        {
            this.setState({loading: false, errorMessage: "Please Fill out Password / Username / Recaptcha"});
        }
        else if(error.status)
        {
            this.setState({loading:false, errorMessage: "Check Network Connection"});
        }
    };

    render()
    {
        const DivLoading = this.state.loading &&
            <FontAwesomeIcon icon={faCircleNotch} pulse/>;

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;
        const DivSuccessMessage = this.state.successMessage &&
            <Callout color={Colors.SUCCESS}>{this.state.successMessage} </Callout>
        const DivAnyMessage = DivSuccessMessage || DivErrorMessage;

        const DivRecaptcha = process.env.REACT_APP_RECAPTCHA_ENABLED &&
            <ReCAPTCHA
                ref={this.ReCaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={this.handleChangeRecaptcha}
                />;
        return (
            <GridContainer full >
                <Cell full>
                    <TopBar>
                        <TopBarLeft>
                            <Menu>
                                <MenuText>Logistics NG</MenuText>
                            </Menu>
                        </TopBarLeft>
                    </TopBar>

                </Cell>
                <Cell full>
                    {/*Loading Thing*/}
                    <form >
                        <h2>{DivLoading} Log in</h2>
                        {DivAnyMessage}
                        <input type="email" id="username" value={this.state.username} onChange={this.handleChangeUsername} autoFocus={true} required={true} placeholder={"Username"}/>
                        <input type="password" id="password" value={this.state.password} onChange={this.handleChangePassword} required={true} placeholder={"Password"}/>
                        {DivRecaptcha}
                        <Button  color={Colors.SUCCESS}type="submit" onClick={this.login}>Sign in</Button>
                    </form>
                </Cell>

                <Cell x></Cell>
            </GridContainer>
        );
    }


}

export default LoginPage;
