import axios from 'axios';

export default class CatalogueItemGateway
{

    static getCatalogueItems(catalogue_id,successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/items/').then(successCallback).catch(errorCallback);
    }

    static createCatalogueItem(catalogue_id,item_name,item_description,barcode_any,successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/items/',
            {
                name: item_name,
                description: item_description,
                barcode_ean_13: ((barcode_any.length===13) && barcode_any) || undefined,
                barcode_upc_a: ((barcode_any.length===12) && barcode_any) || undefined,
                barcode_pzn_8: ((barcode_any.length===8) && barcode_any) || undefined,
            }).then(successCallback).catch(errorCallback);
    }

    static updateCatalogueItem(catalogue_id,item_id,catalogue_name,catalogue_description,barcode_any,successCallback,errorCallback)
    {
        axios.patch(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/items/'+item_id+'/',
            {
                name: catalogue_name,
                description: catalogue_description,
                barcode_ean_13: ((barcode_any.length===13) && barcode_any) || undefined,
                barcode_upc_a: ((barcode_any.length===12) && barcode_any) || undefined,
                barcode_pzn_8: ((barcode_any.length===8) && barcode_any) || undefined,
            }).then(successCallback).catch(errorCallback);
    }

    static getCatalogueItem(catalogue_id,item_id,successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/items/'+item_id+'/').then(successCallback).catch(errorCallback);
    }
    static deleteCatalogueItem(catalogue_id,item_id,successCallback,errorCallback)
    {
        axios.delete(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/items/'+item_id+'/').then(successCallback).catch(errorCallback);
    }



}

