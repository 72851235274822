import axios from 'axios';

export default class CatalogueGateway
{

    static getCatalogues(successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/catalogue/').then(successCallback).catch(errorCallback);
    }

    static createCatalogue(catalogue_name,catalogue_description,successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/catalogue/',
            {
                name: catalogue_name,
                description: catalogue_description,
            }).then(successCallback).catch(errorCallback);
    }

    static updateCatalogue(catalogue_id,catalogue_name,catalogue_description,successCallback,errorCallback)
    {
        axios.patch(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/',
            {
                name: catalogue_name,
                description: catalogue_description,
            }).then(successCallback).catch(errorCallback);
    }

    static getCatalogue(catalogue_id,successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/').then(successCallback).catch(errorCallback);
    }

    static deleteCatalogue(catalogue_id,successCallback,errorCallback)
    {
        axios.delete(process.env.REACT_APP_API_URL+'/catalogue/'+catalogue_id+'/').then(successCallback).catch(errorCallback);
    }

}

