import React,{Component} from 'react';
import AuthStorage from "../Storages/AuthStorage";
import {Callout, Colors} from "react-foundation";

class LogOffPage extends Component {



    constructor(props)
    {
        super(props);
        this.setState({loading: false});
    }

    componentDidMount() {
        AuthStorage.removeSessionKey();
        this.props.history.push("/");//redirect to main after dis authentication
        window.location.reload();
    }


    render()
    {
        return (
            <Callout color={Colors.SECONDARY}>
                <h5> You are beeing Logged Off</h5>
                <p> Your will be forwarded to the Landing Page as soon as you are checked out.</p>
                <a href={"/#"}>Click here, if you are not beeing forwarded. </a>
            </Callout>

        );
    }

}

export default LogOffPage;
