import React, {Component} from 'react';


export default class LocalProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,projects: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Projects'});
    };

    componentDidMount() {

    }


    render() {
        return <h1> Local Profile </h1>;
    }
}

