import React, {Component} from 'react';
import LdapProfileGateway from "../../Gateways/LdapProfileGateway";
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFingerprint,
} from '@fortawesome/pro-duotone-svg-icons'
import {Callout, Colors} from "react-foundation";


export default class LdapProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data:undefined,
            errorMessage: undefined
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Projects'});
    };

    componentDidMount() {
        LdapProfileGateway.loadProfile(this.loadedLdapProfile,this.errorLoading);
    }


    render() {

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const property_uid = this.state.data && this.state.data.uid &&
            <>
                <td><FontAwesomeIcon icon={faFingerprint}/></td>
                <td>{"uid"}</td>
                <td>{this.state.data.uid}</td>
            </>;



        const table =
            <table><tbody>
                <tr>{property_uid}</tr>
                </tbody>
            </table> ;

        return <div>
            {DivErrorMessage}
            {table}


        </div>;
    }
}


