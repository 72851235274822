import axios from 'axios';

class AuthStorage
{
    static SESSION_TOKEN_STORAG_PATH = 'session_token';

    static isLoggedIn()
    {
        return localStorage.getItem(this.SESSION_TOKEN_STORAG_PATH) !== null;
    }

    static saveSessionKey(session_token)
    {
        localStorage.setItem(this.SESSION_TOKEN_STORAG_PATH, session_token);
        axios.defaults.headers.common['Authorization'] = session_token;
    }

    static removeSessionKey()
    {
        localStorage.removeItem(this.SESSION_TOKEN_STORAG_PATH);
    }

    static getSessionKey()
    {
        return localStorage.getItem(this.SESSION_TOKEN_STORAG_PATH);
    }
}
export default AuthStorage;