import React, {Component} from "react";
import CatalogueItemGateway from "../../Gateways/CatalogueItemGateway";
import {Callout, Colors} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSave,
    faLock,
    faUnlock,
    faSync,
    faEraser,
} from "@fortawesome/pro-duotone-svg-icons";

export default class CatalogueItem extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,
            catalogue_id: props.catalogue_id,
            name: props.name || "",
            description: props.description || "",
            barcode: props.barcode_ean || props.barcode_upc_a || props.barcode_pzn_8 || "",
            editable: !props.id && !props.name && !props.description && true,
            errorMessage: undefined
        };
        this.state.id && this.handleChangeSync(undefined);
    }

    componentDidMount() {

    }




    handleChangeName = event => {
         this.state.editable && this.setState({name: event.target.value});
    };

    handleChangeDescription = event => {
        this.state.editable && this.setState({description: event.target.value});
    };

    handleChangeBarcode = event => {
        this.state.editable && this.setState({barcode: event.target.value});
    };

    handleChangeWriteProtection = event => {
        this.setState({editable: !this.state.editable});
    };

    handleChangeSave = event =>{
        if(!this.state.editable)
        {
            return;
        }
        if(this.state.name.trim() === "")
        {
            this.setState({errorMessage: "Name should not be empty"});
            return;
        }
        if(!this.state.id)
        {
            this.setState({loading: true});
            CatalogueItemGateway.createCatalogueItem(this.state.catalogue_id,this.state.name,this.state.description,this.state.barcode,this.handleDataSyncOK,this.handleDataSyncFailure);
        }
        else
        {
            this.setState({loading:true});
            CatalogueItemGateway.updateCatalogueItem(this.state.catalogue_id,this.state.id,this.state.name,this.state.description,this.state.barcode,this.handleDataSyncOK,this.handleDataSyncFailure);
        }
    };



    handleChangeSync = event => {
        this.setState({loading:true});
        CatalogueItemGateway.getCatalogueItem(this.state.catalogue_id,this.state.id,this.handleDataSyncOK,this.handleDataSyncFailure);

    };

    handleDelete = event => {
      this.setState({loading: true});
      CatalogueItemGateway.deleteCatalogueItem(this.state.catalogue_id,this.state.id,this.handleDataSyncOK,this.handleDataSyncFailure);

    };

    handleDataSyncOK = response => {
        if(response.data && (response.status === 200 || response.status === 201))
        {
            this.setState({
                id: response.data.id,
                catalogue_id: response.data.catalogue_id,
                name: response.data.name,
                description: response.data.description || "",
                barcode: response.data.barcode_ean_13 || response.data.barcode_upc_a || response.data.barcode_pzn_8 || "",
                loading:false});
        }
        else if(response.status === 204)
        {
            this.setState({
                id: undefined,
                catalogue_id: undefined,
                name: "",
                description: "",
                barcode: "",
                loading: false});
        }
    };
    handleDataSyncFailure = error => {
        this.setState({loading:false,
            errorMessage: error.data});
    };

    render() {

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const catalogueItem =
                        <div className={"card"} id={this.state.id}>
                            <div className={"card-divider"}>
                                <div className="grid-x grid-margin-x">
                                    <div className="cell small-6">
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeWriteProtection}>
                                            { !this.state.editable &&<FontAwesomeIcon icon={faLock}/> }
                                            { this.state.editable &&<FontAwesomeIcon icon={faUnlock}/> }
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeSave} disabled={!this.state.editable}>
                                            <FontAwesomeIcon icon={faSave}/>
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeSync} disabled={!this.state.editable || (!this.state.id) || this.state.loading} >
                                            <FontAwesomeIcon icon={faSync} spin={this.state.loading}/>
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleDelete} disabled={!this.state.editable || (!this.state.id)}>
                                            <FontAwesomeIcon icon={faEraser}/>
                                        </button>
                                    </div>
                                    <div className="cell auto">
                                        <input disabled={!this.state.editable} type={"text"}  placeholder={"Item Name"} value={this.state.name} onChange={this.handleChangeName}/>
                                    </div>
                                    <div className="cell auto">
                                        <input disabled={!this.state.editable} type={"text"}  placeholder ={"BARCODE"} value={this.state.barcode} onChange={this.handleChangeBarcode}/>
                                    </div>

                                </div>
                            </div>
                            <div className={"card-section"}>
                                <textarea disabled={!this.state.editable} placeholder={"Beschreibung"} onChange={this.handleChangeDescription} value={this.state.description}>
                                </textarea>

                            </div>
                        </div>;

        return (
            <>
                {DivErrorMessage}
                {catalogueItem}
            </>
        );
    }
}