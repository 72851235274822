import React, {Component} from "react";
import CategoryGateway from "../../Gateways/CategoryGateway";
import {Callout, Colors} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSave,
    faLock,
    faUnlock,
    faSync,
    faEraser,
} from "@fortawesome/pro-duotone-svg-icons";

export default class Category extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,
            name: props.name || "",
            editable: !props.id && !props.name && !props.description && true,
            errorMessage: undefined
        };
        this.state.id && this.handleChangeSync(undefined);
    }

    componentDidMount() {

    }

    handleChangeName = event => {
         this.state.editable && this.setState({name: event.target.value});
    };

    handleChangeWriteProtection = event => {
        this.setState({editable: !this.state.editable});
    };

    handleChangeSave = event =>{
        if(!this.state.editable)
        {
            return;
        }
        if(this.state.name.trim() === "")
        {
            this.setState({errorMessage: "Name should not be empty"});
            return;
        }
        if(!this.state.id)
        {
            this.setState({loading: true});
            CategoryGateway.createCategory(this.state.name,this.handleDataSyncOK,this.handleDataSyncFailure);
        }
        else
        {
            this.setState({loading:true});
            CategoryGateway.updateCategory(this.state.id,this.state.name,this.handleDataSyncOK,this.handleDataSyncFailure);
        }
    };



    handleChangeSync = event => {
        this.setState({loading:true});
        CategoryGateway.getCategory(this.state.id,this.handleDataSyncOK,this.handleDataSyncFailure)
    };

    handleDelete = event => {
      this.setState({loading: true});
      CategoryGateway.deleteCategory(this.state.id,this.handleDataSyncOK,this.handleDataSyncFailure)
    };

    handleDataSyncOK = response => {
        if(response.data && (response.status === 200 || response.status === 201))
        {
            this.setState({
                id: response.data.id,
                name: response.data.name,
                loading:false});
        }
        else if(response.status === 204)
        {
            this.setState({
                id: undefined,
                name: "",
                loading: false});
        }
    };

    handleDataSyncFailure = error => {
        this.setState({loading:false,
            errorMessage: error.data});
    };

    render() {

        const DivErrorMessage = this.state.errorMessage &&
            <Callout color={Colors.ALERT}>{this.state.errorMessage} </Callout>;

        const categoryItem =
                        <div className={"card"} id={this.state.id}>
                            <div className={"card-divider"}>
                                <div className="grid-x grid-margin-x">
                                    <div className="cell small-7">
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeWriteProtection}>
                                            { !this.state.editable &&<FontAwesomeIcon icon={faLock}/> }
                                            { this.state.editable &&<FontAwesomeIcon icon={faUnlock}/> }
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeSave} disabled={!this.state.editable}>
                                            <FontAwesomeIcon icon={faSave}/>
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleChangeSync} disabled={!this.state.editable || (!this.state.id) || this.state.loading} >
                                            <FontAwesomeIcon icon={faSync} spin={this.state.loading}/>
                                        </button>
                                        <button type={"button"} className={"hollow button"} onClick={this.handleDelete} disabled={!this.state.editable || (!this.state.id)}>
                                            <FontAwesomeIcon icon={faEraser}/>
                                        </button>
                                    </div>
                                    <div className="cell auto">
                                        <input disabled={!this.state.editable} type={"text"}  placeholder ="Kategorie" value={this.state.name} onChange={this.handleChangeName}/>
                                    </div>
                                </div>
                            </div>
                        </div>;

        return (
            <>
                {DivErrorMessage}
                {categoryItem}
            </>
        );
    }
}